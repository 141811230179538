import _ from 'lodash';
import React from 'react';

type SearchSidePanelAction =
  | { type: 'toggle-side-panel-view' }
  | { type: 'set-search-query'; data: string };
type SearchSidePanelDispatch = (action: SearchSidePanelAction) => void;
type SearchSidePanelState = {
  showSearchSidePanel: boolean;
  searchQuery: string;
  recentSearches: string[];
};
type CountProviderProps = { children: React.ReactNode };

const SearchSidePanelStateContext = React.createContext<
  SearchSidePanelState | undefined
>(undefined);

const SearchSidePanelDispatchContext = React.createContext<
  SearchSidePanelDispatch | undefined
>(undefined);

function searchSidePanelReducer(
  state: SearchSidePanelState,
  action: SearchSidePanelAction,
) {
  switch (action.type) {
    case 'toggle-side-panel-view': {
      return { ...state, showSearchSidePanel: !state.showSearchSidePanel };
    }
    case 'set-search-query': {
      let recentSearches: string[] = [];
      const searchQuery = action.data;
      try {
        const recentSearchesArr = JSON.parse(
          localStorage.getItem('recentSearches') || '[]',
        );
        const alreadyExists = recentSearchesArr.includes(searchQuery);
        if (!alreadyExists && searchQuery)
          recentSearchesArr.unshift(searchQuery);
        recentSearches = _.slice(recentSearchesArr, 0, 5);
        localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
      } catch {
        localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
      }
      return { ...state, searchQuery: searchQuery, recentSearches };
    }
  }
}

function SearchSidePanelProvider({ children }: CountProviderProps) {
  const [state, dispatch] = React.useReducer(searchSidePanelReducer, {
    showSearchSidePanel: false,
    searchQuery: '',
    recentSearches: JSON.parse(localStorage.getItem('recentSearches') || '[]'),
  });

  return (
    <SearchSidePanelStateContext.Provider value={state}>
      <SearchSidePanelDispatchContext.Provider value={dispatch}>
        {children}
      </SearchSidePanelDispatchContext.Provider>
    </SearchSidePanelStateContext.Provider>
  );
}

function useSearchSidePanelState() {
  const context = React.useContext(SearchSidePanelStateContext);

  if (context === undefined) {
    throw new Error(
      'useSearchSidePanelState must be used within a SearchSidePanelProvider',
    );
  }
  return context;
}

function useSearchSidePanelDispatch() {
  const context = React.useContext(SearchSidePanelDispatchContext);

  if (context === undefined) {
    throw new Error(
      'useSearchSidePanelDispatch must be used within a SearchSidePanelProvider',
    );
  }
  return context;
}

export {
  SearchSidePanelProvider,
  useSearchSidePanelState,
  useSearchSidePanelDispatch,
};
