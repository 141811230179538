import React from 'react';

import { TreeItem } from '../tree-item';

const getMenus = () => {
  return [
    { label: 'Dashboard', path: '/dashboard' },
    {
      label: 'Watched cluster',
      path: '/cluster/watched',
    },
  ];
};

export const PrimaryMenus = () => {
  const menus = React.useMemo(() => getMenus(), []);

  return menus.map((menu) => <TreeItem label={menu.label} path={menu.path} />);
};
