import { OpenInNewOutlined } from '@chainalysis/design-system/icons/open-in-new-outlined';
import { useHasCapabilities } from '@frontend-app-shell/auth/data-access';
import React from 'react';

import { TreeItem } from '../tree-item';

const useGetMenus = () => {
  const hasSocialCapability = useHasCapabilities('SOCIAL');
  return [
    hasSocialCapability && {
      label: 'Community',
      children: [
        {
          label: 'Community',
          path: '/reactor/community',
          exactMatch: true,
        },
        {
          label: 'Recent scams',
          path: '/reactor/community/recent-scams',
          exactMatch: true,
        },
        {
          label: 'Ask community',
          path: '/reactor/community/ask-community',
          exactMatch: true,
        },
      ],
    },
    {
      label: 'Developer',
      children: [
        {
          label: 'API Keys',
          path: '/reactor/settings/api-keys/list',
        },
        {
          label: 'API documentation',
          path: 'https://kytdoc.kyt-dev.e.chainalysis.com/#introduction',
          openInNewTab: true,
          endIcon: <OpenInNewOutlined size="small" color="action" />,
        },
      ],
    },
    {
      label: 'Tools',
      children: [
        {
          label: 'Advanced search',
          path: '/reactor/developer/api-keys',
        },
        {
          label: 'Advanced demixing',
          path: 'https://kytdoc.kyt-dev.e.chainalysis.com/#introduction',
        },
        {
          label: 'Seed phrase wallet expansion',
          path: 'https://kytdoc.kyt-dev.e.chainalysis.com/#introduction',
        },
        {
          label: 'Jobs',
          path: 'https://kytdoc.kyt-dev.e.chainalysis.com/#introduction',
        },
        {
          label: 'Directory',
          path: 'https://kytdoc.kyt-dev.e.chainalysis.com/#introduction',
        },
        {
          label: 'Block height',
          path: 'https://kytdoc.kyt-dev.e.chainalysis.com/#introduction',
        },
        {
          label: 'Risk settings',
          path: '/risk/settings/risk-settings/transfer',
        },
        {
          label: 'Block height',
          path: 'https://kytdoc.kyt-dev.e.chainalysis.com/#introduction',
        },
        {
          label: 'Upload custom addresses',
          path: '/risk/custom-addresses/upload',
        },
        { label: 'Preferences', path: '/reactor/settings/preferences' },
      ],
    },
  ];
};

export const TertiaryMenus = () => {
  const menus = useGetMenus();

  return menus.map((menu) => {
    if (!menu) return null;

    return <TreeItem label={menu.label} children={menu.children} />;
  });
};
