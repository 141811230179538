import {
  alertsTableDefaultUrl,
  getTimeRange,
} from '@frontend-app-shell/navbar-utils';
import React from 'react';

import { TreeItem } from '../tree-item';

const getMenus = () => {
  return [
    { label: 'Cases', path: '/cases' },
    {
      label: 'Graphs',
      path: 'reactor/graphs/list',
    },
    {
      label: 'Stories',
      path: '/stories',
    },
    {
      label: 'Alerts',
      path: alertsTableDefaultUrl({ alertStatus: 'Unreviewed' }),
    },
    {
      label: 'Users',
      path: '/risk/users',
    },
    {
      label: 'Transfers',
      path: `/risk/transfers?${getTimeRange(3, 'months')}`,
    },
    {
      label: 'Entities',
      path: '/risk/entities',
    },
  ];
};

export const SecondaryMenus = () => {
  const menus = React.useMemo(() => getMenus(), []);

  return menus.map((menu) => <TreeItem label={menu.label} path={menu.path} />);
};
