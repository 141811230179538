import { set } from 'date-fns';
import { addDays, format, sub } from 'date-fns/fp';
import { flow } from 'lodash';
import queryString from 'query-string';

export const getTimeRange = (num: number, type: string) => {
  const timestampGt = flow(
    sub({ [type]: num }),
    addDays(1),
    format("yyyy-MM-dd'T'HH:mm:ss"),
  )(set(new Date(), { hours: 0, minutes: 0, seconds: 0 }));

  const timestampLt = flow(format("yyyy-MM-dd'T'HH:mm:ss"))(
    set(new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }),
  );

  return queryString.stringify({
    timestamp_gt: timestampGt,
    timestamp_lt: timestampLt,
  });
};

export const alertsTableDefaultUrl = (params: Record<string, string> = {}) =>
  `/risk/alerts?${queryString.stringify({
    ...params,
    sort: 'createdAt desc',
    validity: 'VALID,REVALID',
  })}`;

export const isChildMenuSelected = (path: string) =>
  window.location.pathname.includes(path);

export const openExternalLink = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};
