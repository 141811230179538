import { Loader } from '@frontend-app-shell/shared/ui';
import React from 'react';
import { Outlet } from 'react-router-dom';

const LazyCaseContextProvider = React.lazy(() =>
  import(/* webpackChunkName: "cellar" */ './case-context-provider').then(
    (module) => ({
      default: module.CaseContextProvider,
    }),
  ),
);

export const SuspendedCaseContextProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <React.Suspense fallback={<Loader />}>
    {/* children for component wrapping, Outlet for wrapping inside routes */}
    <LazyCaseContextProvider>{children || <Outlet />}</LazyCaseContextProvider>
  </React.Suspense>
);
