// sort-imports-ignore
import './app/datadog-init';
import './styles.css';

import { AuthProvider } from '@frontend-app-shell/auth/data-access';
import { initI18n } from '@frontend-app-shell/shared-utils';
import * as ReactDOM from 'react-dom/client';

import App from './app/app';

initI18n();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
);
