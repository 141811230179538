import { AuthContext } from '@frontend-app-shell/auth/data-access';
import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';

import { Avatar } from '@chainalysis/design-system/components/avatar';

function getInitials(text: string) {
  const splitName = text.trim().split(' ');
  let initials = splitName[0].charAt(0).toUpperCase();

  if (splitName.length > 1) {
    initials += splitName[splitName.length - 1].charAt(0).toUpperCase();
  }
  return initials;
}

export const ProfileMenus = () => {
  const { userInfo } = React.useContext(AuthContext);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: 'background.paper',
        border: theme.borders.outline,
        borderRadius: '8px',
        padding: '8px',
      })}
    >
      {!userInfo || userInfo.uuid === '' ? (
        <Skeleton variant="circular" width={24} height={24} />
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            content={{ type: 'letters', value: getInitials(userInfo.fullName) }}
            size="24px"
          />
          <Typography variant="caption1" sx={{ marginLeft: '8px' }}>
            {userInfo.fullName}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
