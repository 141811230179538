import _ from 'lodash';
import React from 'react';

type NavbarAction =
  | { type: 'toggle-expanded-view' }
  | { type: 'set-search-query'; data: string };
type NavbarDispatch = (action: NavbarAction) => void;
type NavbarState = {
  expanded: boolean;
  searchQuery: string;
  recentSearches: string[];
};
type CountProviderProps = { children: React.ReactNode };

const NavbarStateContext = React.createContext<NavbarState | undefined>(
  undefined,
);

const NavbarDispatchContext = React.createContext<NavbarDispatch | undefined>(
  undefined,
);

function NavbarReducer(state: NavbarState, action: NavbarAction) {
  switch (action.type) {
    case 'toggle-expanded-view': {
      return { ...state, expanded: !state.expanded };
    }
    case 'set-search-query': {
      let recentSearches: string[] = [];
      const searchQuery = action.data;
      try {
        const recentSearchesArr = JSON.parse(
          localStorage.getItem('recentSearches') || '[]',
        );
        const alreadyExists = recentSearchesArr.includes(searchQuery);
        if (!alreadyExists && searchQuery)
          recentSearchesArr.unshift(searchQuery);
        recentSearches = _.slice(recentSearchesArr, 0, 5);
        localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
      } catch {
        localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
      }
      return { ...state, searchQuery: searchQuery, recentSearches };
    }
  }
}

function NavbarProvider({ children }: CountProviderProps) {
  const [state, dispatch] = React.useReducer(NavbarReducer, {
    expanded: true,
    searchQuery: '',
    recentSearches: JSON.parse(localStorage.getItem('recentSearches') || '[]'),
  });

  return (
    <NavbarStateContext.Provider value={state}>
      <NavbarDispatchContext.Provider value={dispatch}>
        {children}
      </NavbarDispatchContext.Provider>
    </NavbarStateContext.Provider>
  );
}

function useNavbarState() {
  const context = React.useContext(NavbarStateContext);

  if (context === undefined) {
    throw new Error('useNavbarState must be used within a NavbarProvider');
  }
  return context;
}

function useNavbarDispatch() {
  const context = React.useContext(NavbarDispatchContext);

  if (context === undefined) {
    throw new Error('useNavbarDispatch must be used within a NavbarProvider');
  }
  return context;
}

export { NavbarProvider, useNavbarState, useNavbarDispatch };
