import { matchRoutes, useLocation } from 'react-router-dom';

import { privateRoutes } from '../utils/private-routes';

export const useGetCurrentRoute = () => {
  const location = useLocation();
  const matchedRoutes = matchRoutes(privateRoutes, location);

  return matchedRoutes && matchedRoutes[matchedRoutes.length - 1].route;
};
