import {
  SuspendedCaseContextProvider,
  SuspendedCaseHomeContainer,
  SuspendedCasePageContainer,
} from '@frontend-app-shell/case-ui';
import { Navigate } from 'react-router-dom';

export const privateRoutes = [
  { path: '/', element: <Navigate to="/cases" />, index: true, replace: true },
  { path: '/version', element: <div>Version 1.0.0</div> },
  {
    path: 'cases',
    element: <SuspendedCaseContextProvider />,
    name: 'My work',
    children: [
      {
        path: '',
        element: <SuspendedCaseHomeContainer />,
        name: 'My work',
        iframe: 'all',
      },
      {
        path: ':caseId',
        element: <SuspendedCasePageContainer />,
        name: 'My case',
        iframe: 'all',
      },
    ],
  },

  // storyline paths
  {
    path: 'investigations/stories/*',
    name: 'Stories',
    iframe: 'investigations-storyline',
  },

  //  reactor paths
  {
    path: 'investigations/*',
    name: 'Investigate',
    iframe: 'investigations-graph',
  },
  // kyt paths
  { path: 'compliance/*', name: 'Monitor', iframe: 'compliance' },

  // playbook (growth) paths
  { path: 'growth/*', name: 'Growth', iframe: 'growth' },
];
