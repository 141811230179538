import { Capability } from '@frontend-app-shell/auth/types';

import { useGetAuthContext } from './use-get-auth-context';

export const useHasCapabilities = (...capabilities: Capability[]): boolean => {
  const authContext = useGetAuthContext();

  return capabilities.every((capability) =>
    authContext.token?.capabilities.includes(capability),
  );
};
