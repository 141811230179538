import {
  ErrorBoundary,
  PrivateRouteLayout,
} from '@frontend-app-shell/routes/components';
import { privateRoutes } from '@frontend-app-shell/routes/utils';

export const privateAppRoutes = [
  {
    path: '/',
    element: <PrivateRouteLayout />,
    errorElement: <ErrorBoundary />,
    name: '',
    children: privateRoutes,
  },
];
