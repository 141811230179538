import { ProductIframe } from '@frontend-app-shell/iframes';
import type { ActiveIframeType } from '@frontend-app-shell/iframes';
import { TOP_NAV_HEIGHT } from '@frontend-app-shell/navbar-utils';
import { useGetCurrentRoute } from '@frontend-app-shell/routes/utils';
import { useGetFeatureFlag } from '@frontend-app-shell/shared-utils';
import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const IframeRouteLayout = () => {
  const currentRoute = useGetCurrentRoute();
  const iframeRoute = currentRoute?.iframe;
  const [loadedIframes, setLoadedIframes] = React.useState(new Set());
  const [activeIframe, setActiveIframe] = React.useState<ActiveIframeType>('');
  const { enabled: showUnifiedNavbarEnabled } = useGetFeatureFlag(
    'shell_show_unified_navbar',
  );
  React.useEffect(() => {
    if (iframeRoute) {
      setLoadedIframes(loadedIframes.add(iframeRoute));
    }
  }, [iframeRoute, loadedIframes]);

  React.useEffect(() => {
    const currentIframe = currentRoute?.iframe;

    if (!currentIframe) {
      return setActiveIframe('compliance');
    }
    if (currentIframe !== 'all') {
      return setActiveIframe(currentIframe as ActiveIframeType);
    }
    if (currentIframe === 'all' && activeIframe === '') {
      return setActiveIframe('compliance');
    }
  }, [activeIframe, currentRoute?.iframe]);

  return (
    <>
      {!activeIframe && showUnifiedNavbarEnabled && (
        <Box sx={{ width: '100%' }}>
          <Outlet />
        </Box>
      )}
      <Box
        sx={{
          opacity: !activeIframe ? 0 : 1,
          ...(showUnifiedNavbarEnabled
            ? {
                height: !activeIframe
                  ? '0'
                  : `calc(100% - ${TOP_NAV_HEIGHT}px)`,
              }
            : {
                height: !activeIframe ? '0' : '100%',
              }),
          width: '100%',
          transition: 'opacity 0.1s linear',
          transitionDelay: '0.2s',
        }}
      >
        {activeIframe === 'compliance' || loadedIframes.has('compliance') ? (
          <ProductIframe
            src={import.meta.env.VITE_KYT_FRONTEND_URL}
            product="compliance"
            activeIframe={activeIframe}
          />
        ) : null}
        {activeIframe === 'investigations-graph' ||
        loadedIframes.has('investigations-graph') ? (
          <ProductIframe
            src={import.meta.env.VITE_REACTOR_FRONTEND_URL}
            product="investigations-graph"
            activeIframe={activeIframe}
          />
        ) : null}
        {activeIframe === 'investigations-storyline' ||
        loadedIframes.has('investigations-storyline') ? (
          <ProductIframe
            src={import.meta.env.VITE_STORYLINE_FRONTEND_URL || ''}
            product="investigations-storyline"
            activeIframe={activeIframe}
          />
        ) : null}
        {activeIframe === 'growth' || loadedIframes.has('growth') ? (
          <ProductIframe
            src={import.meta.env.VITE_PLAYBOOK_FRONTEND_URL || ''}
            product="growth"
            activeIframe={activeIframe}
          />
        ) : null}
      </Box>
    </>
  );
};
