import '@chainalysis/design-system/fonts.css';
import type {} from '@chainalysis/design-system/theme/augments';
import { AuthContext } from '@frontend-app-shell/auth/data-access';
import { NavbarProvider } from '@frontend-app-shell/navbar-utils';
import { SearchSidePanelProvider } from '@frontend-app-shell/search-ui';
import { Loader } from '@frontend-app-shell/shared/ui';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { RouterProvider } from 'react-router-dom';

import { privateAppRoutes } from './private-app-routes';
import { ThemeProvider } from './theme-provider';

const splitIoSdkConfig = {
  core: {
    authorizationKey: import.meta.env.VITE_SPLITIO_SDK_KEY || '',
    key: 'anonymous',
  },
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      retry: false,
    },
  },
});

export function App() {
  const { isAuthenticated, isCheckingAuth } = React.useContext(AuthContext);

  if (isCheckingAuth) {
    return <Loader variant="container" />;
  }

  if (!isAuthenticated) {
    window.location.href = `${import.meta.env.VITE_LOGIN_URL}?redirect=${
      window.location.href
    }`;
    return;
  }

  return (
    <SplitFactoryProvider config={splitIoSdkConfig}>
      <NavbarProvider>
        <SearchSidePanelProvider>
          <ThemeProvider>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={createBrowserRouter(privateAppRoutes)} />
            </QueryClientProvider>
          </ThemeProvider>
        </SearchSidePanelProvider>
      </NavbarProvider>
    </SplitFactoryProvider>
  );
}

export default App;
