import { Box, Stack, Typography } from '@mui/material';
import { useRouteError } from 'react-router';
import { useNavigate } from 'react-router-dom';

import noInternetIcon from './no-internet.png';

export const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  if (!error) return null;

  // eslint-disable-next-line no-console
  console.warn('error', (error as Error).message);

  return (
    <Box
      style={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Stack gap={3}>
        <img
          src={noInternetIcon}
          alt="No internet"
          width="218px"
          height="177px"
        />
        <Box>
          <Typography variant="h3" color="text.secondary">
            Something went wrong...
          </Typography>
          <Typography
            variant="caption1"
            textAlign="center"
            component="div"
            color="text.secondary"
          >
            Please try{' '}
            <Typography
              variant="caption1"
              component="a"
              sx={{
                textDecoration: 'underline',
                '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
              }}
              onClick={() => window.location.reload()}
            >
              reloading this page
            </Typography>
          </Typography>
          <Typography
            variant="caption1"
            textAlign="center"
            component="div"
            color="text.secondary"
          >
            Or{' '}
            <Typography
              variant="caption1"
              component="a"
              sx={{
                textDecoration: 'underline',
                '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
              }}
              onClick={() => navigate('/')}
            >
              return to home
            </Typography>
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
