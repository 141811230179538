import { datadogLogs } from '@datadog/browser-logs';
import {
  RumEvent,
  RumEventDomainContext,
  datadogRum,
} from '@datadog/browser-rum';

datadogRum.init({
  applicationId: 'f93aa24e-cf4b-43ae-ba2e-cf211f006674',
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'frontend-app-shell',
  env: import.meta.env.VITE_ENV,
  version: import.meta.env.VITE_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  beforeSend: (event: RumEvent, _context: RumEventDomainContext) => {
    // ignore this error source for now as it's cauing a lot of noise in datadog
    // tldr; report is not an error it's just using ReportingObserver API to send browser reports
    if (event.type === 'error' && event.error?.source === 'report') {
      return false;
    }
    return true;
  },
});

datadogLogs.init({
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'frontend-app-shell',
  env: import.meta.env.VITE_ENV,
  version: import.meta.env.VITE_VERSION,
  sessionSampleRate: 100,
  forwardErrorsToLogs: true,
});
