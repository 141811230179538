import { ExpandLessOutlined } from '@chainalysis/design-system/icons/expand-less-outlined';
import { ExpandMoreOutlined } from '@chainalysis/design-system/icons/expand-more-outlined';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type TreeItemProps = {
  label?: string;
  path?: string;
  exactMatch?: boolean;
  openInNewTab?: boolean;
  children?: TreeItemProps[];
  endIcon?: React.ReactNode;
};

export const TreeItem = ({
  label,
  path,
  exactMatch,
  openInNewTab,
  children,
  endIcon,
}: TreeItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const selected = (() => {
    if (exactMatch) return path === location.pathname;
    return path && path.startsWith(location.pathname);
  })();
  const [collapsed, setCollapsed] = React.useState(true);

  const onClickTreeItem = () => {
    if (path) {
      if (openInNewTab) {
        window.open(path, '_blank');
      } else navigate(path);
    } else {
      setCollapsed(!collapsed);
    }
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          width: '100%',
          height: '36px',
          padding: '8px',
          borderRadius: '8px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...(selected
            ? {
                backgroundColor: theme.palette.action.selected,
              }
            : {
                '&:hover': {
                  background: theme.palette.action.hover,
                },
              }),
        })}
        onClick={onClickTreeItem}
      >
        <Typography variant={selected ? 'subtitle2' : 'body2'}>
          {label}
        </Typography>
        <Box sx={{ display: 'flex' }}>
          {endIcon}
          {!path &&
            (collapsed ? (
              <ExpandMoreOutlined color="action" size="small" />
            ) : (
              <ExpandLessOutlined color="action" size="small" />
            ))}
        </Box>
      </Box>
      {!collapsed && children && (
        <Box sx={{ padding: '4px 0 2px 12px' }}>
          {children.map((child) => (
            <TreeItem key={child.label} {...child} />
          ))}
        </Box>
      )}
    </>
  );
};
