import { Loader } from '@frontend-app-shell/shared/ui';
import React from 'react';

const LazyCasePageContainer = React.lazy(() =>
  import(/* webpackChunkName: "cellar" */ './case-page-container').then(
    (module) => ({ default: module.CasePageContainer }),
  ),
);

export const SuspendedCasePageContainer = () => (
  <React.Suspense fallback={<Loader />}>
    <LazyCasePageContainer />
  </React.Suspense>
);
