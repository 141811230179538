import { MenuOutlined } from '@chainalysis/design-system/icons/menu-outlined';
import {
  NAVBAR_WIDTH,
  TOP_NAV_HEIGHT,
  useNavbarDispatch,
  useNavbarState,
} from '@frontend-app-shell/navbar-utils';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

interface AppBarProps extends MuiAppBarProps {
  expanded?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, expanded }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(expanded && {
    width: `calc(100% - ${NAVBAR_WIDTH}px)`,
    marginLeft: `${NAVBAR_WIDTH}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const TopNavbar = () => {
  const { expanded } = useNavbarState();
  const dispatch = useNavbarDispatch();

  const expandNavbar = () => {
    dispatch({ type: 'toggle-expanded-view' });
  };

  return (
    <AppBar
      expanded={expanded}
      sx={{
        backgroundColor: 'background.default',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0px -1px 0px inset',
      }}
    >
      <Toolbar
        sx={{
          height: `${TOP_NAV_HEIGHT}px !important`,
          minHeight: `${TOP_NAV_HEIGHT}px !important`,
        }}
      >
        <IconButton
          aria-label="open drawer"
          onClick={expandNavbar}
          edge="start"
          sx={{ mr: 2, ...(expanded && { display: 'none' }) }}
        >
          <MenuOutlined />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
