import type { Pendo, PendoAccountInfo, PendoVisitorInfo } from '../types/pendo';

declare global {
  interface Window {
    pendo: Pendo;
  }
}

export const pendo = window.pendo;

export const pendoInit = (
  visitorInfo: PendoVisitorInfo,
  accountInfo: PendoAccountInfo,
) => {
  if (pendo) {
    pendo.initialize({
      visitor: visitorInfo,
      account: accountInfo,
    });
  }
  return null;
};
