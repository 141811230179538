import { DoubleArrowLeftOutlined } from '@chainalysis/design-system/icons/double-arrow-left-outlined';
import {
  NAVBAR_WIDTH,
  useNavbarDispatch,
  useNavbarState,
} from '@frontend-app-shell/navbar-utils';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

import { LogoIcon } from './logo-icon';
import { PrimaryMenus } from './primary-menus';
import { ProfileMenus } from './profile-menus';
import { SecondaryMenus } from './secondary-menus';
import { TertiaryMenus } from './tertiary-menus';
import { TopNavbar } from './top-navbar';

export const Navbar = () => {
  const { expanded } = useNavbarState();
  const dispatch = useNavbarDispatch();
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    dispatch({ type: 'toggle-expanded-view' });
  };

  const handleLogoClick = () => {
    navigate('/cases');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <TopNavbar />
      <Drawer
        sx={{
          width: NAVBAR_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            padding: '0 16px',
            width: NAVBAR_WIDTH,
            overflowX: 'hidden',
          },
          backgroundColor: 'background.paper',
        }}
        variant="persistent"
        anchor="left"
        open={expanded}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'background.paper',
            paddingTop: '12px',
          }}
        >
          <Box
            sx={{
              padding: 0,
              minHeight: '32px !important',
              marginBottom: '12px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              color="accent.main"
              onClick={handleLogoClick}
              sx={{
                display: 'flex',
                gap: '8px',
                height: '32px',
                width: '100%',
              }}
            >
              <LogoIcon />
              <Typography variant="productName">Chainalysis</Typography>
            </Box>
            <IconButton onClick={handleDrawerClose} size="small">
              <DoubleArrowLeftOutlined />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <PrimaryMenus />
          <Divider sx={{ margin: '8px 0' }} />
          <SecondaryMenus />
          <Divider sx={{ margin: '8px 0' }} />
          <TertiaryMenus />
        </Box>
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'background.paper',
            paddingBottom: '12px',
          }}
        >
          <ProfileMenus />
        </Box>
      </Drawer>
    </Box>
  );
};
