/* eslint-disable react/require-default-props */
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export type LoaderProps = {
  description?: string;
  variant?: 'container' | '';
};

export const Loader = ({
  description = undefined,
  variant = '',
}: LoaderProps) => {
  const getSx = () => {
    switch (variant) {
      case 'container':
        return {
          minWidth: '100%',
          height: 'calc(100% - 65px)',
        };
      case '':
        return {
          minWidth: '100%',
        };
      default:
        return {};
    }
  };

  return (
    <Box
      data-testid="loader-container"
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      sx={{ ...getSx() }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <CircularProgress data-testid="loader-center" />
        <Typography variant="subtitle1">{description}</Typography>
      </Stack>
    </Box>
  );
};
