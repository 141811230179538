import axios from 'axios';

export const SSO = axios.create({
  baseURL: `${import.meta.env.VITE_UMAPI || ''}`,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
});

const client = axios.create({
  baseURL: import.meta.env.VITE_UMAPI,
  headers: { 'X-Requested-With': 'XMLHttpRequest', Accept: 'application/json' },
  withCredentials: true,
});

export const getToken = async () => {
  const userInfoResponse = await SSO.get('/api/sso/auth/userinfo');
  const userInfo = userInfoResponse.data;

  localStorage.setItem('tokenLogout', userInfo.csrfToken);

  if (userInfo.organizations[0].idpUrl) {
    localStorage.setItem('idpUrl', userInfo.organizations[0].idpUrl);
  }

  const tokenRes = await SSO.post('/api/sso/auth/token', {
    orgId: userInfo.organizations[0].id,
    productName: 'Reactor',
  });

  localStorage.setItem('UMToken', tokenRes.data.token);
  client.defaults.headers.Token = tokenRes.data.token;

  return { userInfo, token: tokenRes.data };
};
