import { AuthContext } from '@frontend-app-shell/auth/data-access';
import { Navbar } from '@frontend-app-shell/navbar-ui';
import {
  NAVBAR_WIDTH,
  TOP_NAV_HEIGHT,
  useNavbarState,
} from '@frontend-app-shell/navbar-utils';
import { pendoInit } from '@frontend-app-shell/pendo';
import {
  SIDE_PANEL_WIDTH,
  SearchSidePanel,
  useSearchSidePanelState,
} from '@frontend-app-shell/search-ui';
import { useGetFeatureFlag } from '@frontend-app-shell/shared-utils';
import { DrawerHeader, Loader } from '@frontend-app-shell/shared/ui';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { IframeRouteLayout } from '../iframe-route-layout';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  height: '100%',

  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${NAVBAR_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  position: 'relative',
  minWidth: 0,
}));

export const PrivateRouteLayout = () => {
  const { expanded } = useNavbarState();
  const { userInfo } = useContext(AuthContext);
  const { enabled, isReady } = useGetFeatureFlag('shell_show_unified_navbar');

  useEffect(() => {
    const { userId, email, organizations } = userInfo;

    pendoInit(
      { id: userId, email: email },
      { id: organizations[0].id, name: organizations[0].orgName },
    );
  }, [userInfo]);

  if (!isReady) {
    return <Loader variant="container" />;
  }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      {enabled ? (
        <>
          <Navbar />
          <Main open={expanded}>
            {enabled && <DrawerHeader />}
            <IframeRouteLayout />
          </Main>
        </>
      ) : (
        <IframeRouteLayout />
      )}

      {/* <SearchSidePanel /> */}
    </Box>
  );
};
