import { SvgIcon, SvgIconProps } from '@mui/material';

export const LogoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 21 21" sx={{ fontSize: '29.17px' }} {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M10.5 0C10.7352 0 11.0019 0.0294 11.0019 0.0294C10.2128 0.975841 9.73724 2.1441 9.64108 3.3726C8.15424 3.62133 6.7854 4.33752 5.73331 5.41717C4.68122 6.49683 4.00067 7.88373 3.79048 9.3765C2.43178 9.5235 0.894575 10.1451 0.0104747 11.0334C-0.199525 4.9392 4.47088 0 10.5 0ZM12.012 18.3078C11.7339 18.3489 11.4531 18.3693 11.172 18.3687C10.6868 18.3629 10.2026 18.3229 9.72298 18.249C9.34828 19.1512 8.85384 19.9988 8.25298 20.769C6.36825 20.3534 4.63454 19.4263 3.24232 18.0896C1.85009 16.753 0.853203 15.0584 0.361175 13.1922C1.30827 12.0687 3.49648 11.4177 4.85518 11.4177C7.75318 11.4177 9.29038 12.9549 9.82378 15.351C8.07868 14.8785 7.04338 13.902 6.36298 12.453C5.96535 12.3086 5.54486 12.2375 5.12188 12.243C4.74019 12.2414 4.36078 12.3017 3.99838 12.4215C4.76698 15.351 7.60618 17.5392 11.0649 17.5392C14.259 17.5392 15.6177 15.9138 15.6177 15.9138V13.4274C15.6177 13.4274 14.0511 15.0549 12.1002 15.3804C11.8807 13.8615 11.174 12.4549 10.0864 11.3721C8.99869 10.2894 7.58889 9.58907 6.06898 9.3765C6.57088 6.8922 8.46298 5.502 11.0355 5.502C13.608 5.502 15.6786 7.3647 15.6786 7.3647V4.9098C15.6786 4.9098 14.3472 3.6666 11.9826 3.402C12.0414 2.31 12.7512 0.9471 13.5198 0.4431C16.7412 1.449 19.8471 4.1706 20.7333 8.1333C20.7333 8.1333 19.3137 9.5529 16.653 9.5529C14.4648 9.5529 13.083 8.4588 12.306 6.5373C11.8984 6.39212 11.4681 6.321 11.0355 6.3273C10.6317 6.32288 10.2307 6.39411 9.85318 6.5373C10.71 9.6138 13.4232 11.6823 16.4472 11.6823C19.404 11.6823 21 10.3236 21 10.3236C21 16.4745 16.2981 20.8824 10.71 21C11.361 20.37 11.8629 18.9882 12.012 18.3078Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
