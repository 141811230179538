import { AuthContext } from '@frontend-app-shell/auth/data-access';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import React from 'react';

export const useGetFeatureFlag = (featureFlag: string) => {
  const { userInfo } = React.useContext(AuthContext) || {};
  const organization = userInfo?.organizations?.[0];

  const { treatments, isReady } = useSplitTreatments({
    names: [featureFlag],
    attributes: {
      orgName: organization?.orgName ?? 'unknown',
      orgId: organization?.id ?? -1,
      userName: userInfo?.userName ?? '',
    },
  });

  return { enabled: treatments[featureFlag]?.treatment === 'on', isReady };
};
