import { CdsProvider, createTheme } from '@chainalysis/design-system/core';
import { Paper } from '@mui/material';
import React from 'react';

type ThemeProviderProps = {
  children: React.ReactNode;
};

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const theme = createTheme('light');

  return (
    <CdsProvider theme={theme}>
      <Paper sx={{ height: '100%' }}>{children}</Paper>
    </CdsProvider>
  );
};
