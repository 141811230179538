import type { AuthState } from '@frontend-app-shell/auth/types';
import { AxiosError } from 'axios';
import React from 'react';

import { getToken } from '../utils/sso-request';

const defaultState = {
  isAuthenticated: false,
  isCheckingAuth: true,
  userInfo: {
    accountCreationDate: 1,
    fullName: '',
    userId: 1,
    userName: '',
    userPermissions: [],
    organizations: [],
    email: '',
    products: [],
    uuid: '',
  },
  token: undefined,
};

export const AuthContext = React.createContext<AuthState>({
  ...defaultState,
  checkAuth: () => new Promise(() => {}),
});

type Props = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
  const [authState, setAuthState] = React.useState(defaultState);

  const checkAuth = React.useCallback(async () => {
    setAuthState({ ...authState, isCheckingAuth: true });

    try {
      const { userInfo, token } = await getToken();

      setAuthState({
        isCheckingAuth: false,
        isAuthenticated: true,
        userInfo,
        token,
      });
    } catch (e: unknown) {
      const { response } = e as AxiosError;
      if (response?.status === 401) {
        setAuthState({
          ...authState,
          isCheckingAuth: false,
          isAuthenticated: false,
        });
      } else {
        throw new Error((e as AxiosError).toString());
      }
    }
  }, [authState]);

  React.useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        checkAuth: checkAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
