import { Loader } from '@frontend-app-shell/shared/ui';
import React from 'react';

const LazyCaseHomeContainer = React.lazy(() =>
  import(/* webpackChunkName: "cellar" */ './case-home-container').then(
    (module) => ({ default: module.CaseHomeContainer }),
  ),
);

export const SuspendedCaseHomeContainer = () => (
  <React.Suspense fallback={<Loader />}>
    <LazyCaseHomeContainer />
  </React.Suspense>
);
